
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { SummerActRewardPoolAPI } from "@/common/api/operationManage/summerActRewardPoolList";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { CommonApi } from "@/common/api/common";

import {
  shareConfirmOptions,
  getCommonList,
  shareShopType2ch,
  // chooseShopTypeList,
} from "@/common/config";

export const pageApiList = getPageAPIs(SummerActRewardPoolAPI);

const defaultForms = {
  reward_name: "",
  // origin_price: "",
  // sale_price: "",
  type: '', // 类型 1实物 2虚拟物品

  num: "", // 库存

  reward_rate: "", // 中奖概率

  avatar: "", // 图片
  avatar_id: "", // 头像id

  type_icon: '',

  sort: "", // 排序

  coupons_platform_id:'', //平台小时券

  member_price: '', // 会员金额
};

const summerActRewardPoolDialogTitle2ch: any = {
  "new-summer-act-reward-pool": "添加",
  "edit-summer-act-reward-pool": "编辑",
};

// const jumpType2ch: any = [
//   "不跳转",
//   "打开URL",
//   "跳转店铺详情",
//   "比赛活动页",
//   "会员中心",
//   "邀请好友",
//   "跳转“熊猫杯”精英会员比赛活动",
//   "招募合伙人",
//   "跳转周榜奖励列表",
// ];

// const summerActRewardPoolStatus2ch: any = ["下架", "上架"];

// // 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// const summerActRewardPoolShopType: any = [...shareShopType2ch];

//最大金额 黄金会员
const maxMemberPrice = 500;

// 类型 1实物 2虚拟物品
const rewardType2ch: string[] = ['', '实物', '钻石','平台小时券','全国会员']

@Component({
  filters: {
    // showJumpTypeName(jump_type: number) {
    //   return jumpType2ch[jump_type] || "未知";
    // },
    // showSummerActRewardPoolStatusName(is_enable: number) {
    //   return summerActRewardPoolStatus2ch[is_enable] || "未知";
    // },

    rewardTypeName(type: number) {
      return rewardType2ch[type] || "未知";
    },
  },
})
export default class SummerActRewardPoolsManage extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private defaultRewardForms:any = {
    id:''
  }


  private commonApi: any;

  private deepLoopUtil: any = {};

  private showRewardTypeList: any[] = [
    ...getCommonList(rewardType2ch, 1)
  ]

  // private allJumpTypeList: any[] = [];

  // private get showJumpTypeList() {
  //   const allJumpTypeList = this.allJumpTypeList;

  //   const forms = this.forms;

  //   const { shop_type } = forms || {};

  //   const belongToBall = [3, 4, 5, 6, 7, 8]; // 仅属于台球项目

  //   const belogToMerchants = [0, 1]; // 属于商家端 包含 球社 以及 国粹馆

  //   if (shop_type == 2) {
  //     // 属于国粹馆
  //     return allJumpTypeList.filter(
  //       (item) => !belongToBall.includes(item.value)
  //     );
  //   }

  //   return shop_type === 1
  //     ? allJumpTypeList
  //     : allJumpTypeList.filter((item) => belogToMerchants.includes(item.value));
  // }

  private refreshJumpTypeInfo() {
    this.$set(this.forms, "jump_type", "");
  }

  private summerActRewardPoolApi = new SummerActRewardPoolAPI();
  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.commonApi = new CommonApi();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    /** 暂时屏蔽 6 类型的 跳转  */
  //   this.allJumpTypeList = jumpType2ch
  //     .map((item: string, index: number) => {
  //       return {
  //         label: item,
  //         value: index,
  //       };
  //     })
  //     .filter((item: any) => item.value !== 6);
  }

  // private belongShopTypeList: any[] = [
  //   // {
  //   //   label: '熊猫台球',
  //   //   value: 1
  //   // },
  //   // {
  //   //   label: '熊猫国粹馆',
  //   //   value: 2
  //   // },
  //   // {
  //   //   label: '熊猫球社商家端',
  //   //   value: 3
  //   // },
  //   // {
  //   //   label: '熊猫国粹馆商家端',
  //   //   value: 4
  //   // }
  //   ...chooseShopTypeList,
  // ];

  private created(): void {
    this.getSummerActRewardPools();
    this.getPlatformCouponsList()
    this.getDefaultRewardInfo()
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getSummerActRewardPools();
  }

  private tableData: any[] = [];
  private defaultList:any[] = []

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getSummerActRewardPools();
  }

  private addOrUpdateSummerActRewardPoolTitle: string = "奖品配置";

  private dialogFormVisible: boolean = false;

  private defaultRewardVisible: boolean = false;

  // 拉起summerActRewardPool弹框
  private handleWakeSummerActRewardPoolDialog(mode: string) {
    // if (mode == 'new-summer-act-reward-pool') { // 新增卡券

    // }
    this.summerActRewardPoolDialogMode = mode;
    this.addOrUpdateSummerActRewardPoolTitle = summerActRewardPoolDialogTitle2ch[mode] || "奖品配置";

    this.initSummerActRewardPoolForm(mode);

    this.dialogFormVisible = true;
  }

  private handleDefaultRewardDialog():void{
    this.summerActRewardPoolApi.getSummerActRewardPools(
        { page:1,limit: 99999 },
        (res: any) => {
          this.defaultList = res.data.list;
          this.defaultRewardVisible = true
        },
        () => {}
    );
  }

  // 删除 summerActRewardPool
  handleDeleteSummerActRewardPool(row: any) {
    this.$confirm("确定删除该奖品配置?", "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.summerActRewardPoolApi.deleteSummerActRewardPools(
        {
          id: row.id,
        },
        () => {
          this.getSummerActRewardPools();
        },
        () => {}
      );
    });
  }

  private currentSummerActRewardPoolItem: any = null;

  private summerActRewardPoolDialogMode: string = "";

  private async initSummerActRewardPoolForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-summer-act-reward-pool") {
      // 编辑
      // await this.getMoreVenueList(true)

      const currentSummerActRewardPoolItem = this.currentSummerActRewardPoolItem;

      const {
        id,
        reward_name,
        type,
        num,
        reward_rate,
        image_url: avatar,
        image_id: avatar_id,
        sort,
        type_icon,
        coupons_platform_id,
        member_price
      } = currentSummerActRewardPoolItem || {};

      if (reward_name) {
        forms.reward_name = reward_name;
      }

      if (typeof type == "number") {
        forms.type = type;
      }

      if (typeof num == 'number') {
        forms.num = num.toString();
      }

      if (reward_rate) {
        forms.reward_rate = reward_rate;
      }

      if (typeof sort == "number") {
        forms.sort = sort.toString();
      }

      if (typeof type_icon == "number") {
        forms.type_icon = type_icon.toString();
      }

      if (coupons_platform_id) {
        forms.coupons_platform_id = coupons_platform_id;
      }


      if (member_price) {
        forms.member_price = member_price;
      }

      if (typeof avatar_id == "number") {
        forms.avatar = avatar;

        forms.avatar_id = avatar_id;
      }

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditSummerActRewardPoolDialog(couponItem: any) {
    this.currentSummerActRewardPoolItem = couponItem;

    this.handleWakeSummerActRewardPoolDialog("edit-summer-act-reward-pool");
  }

  private handleLimitNum(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "num", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "num", new_val);
      }
    );
  }

  private handleLimitTypeIcon(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "type_icon", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "type_icon", new_val);
      }
    );
  }

  private handleLimitRate(val: string) {
    Util.limitDigitalnput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "reward_rate", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "reward_rate", new_val);
      },
      4
    );
  }

  // 有效期
  // private handleLimitValueObject(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "value_object", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "value_object", new_val);
  //     }
  //   );
  // }

  // 上传头像
  private userAvatarUploading: boolean = false;
  private handleUploadUserAvatar(file: any, fileList: any[]) {
    console.log(file, fileList, "file", "fileList");

    this.userAvatarUploading = true;
    this.commonApi.getOss(
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadImgObjWrap } = data;

        const { item: uploadImgObj } = uploadImgObjWrap;

        const { id, url } = uploadImgObj;

        this.$set(this.forms, "avatar_id", id);
        this.$set(this.forms, "avatar", url);

        this.userAvatarUploading = false;
      },
      () => {
        this.$message.error("图片上传失败");

        this.userAvatarUploading = false;
      }
    );
  }

  // 排序
  private handleLimitSort(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        // const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
        this.$set(this.forms, "sort", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "sort", new_val);
      }
    );
  }


  // 会员金额
  private handleLimitMemberPrice(val: string) {
    Util.limitIntegetInput(
        val,
        (new_val: string) => {
          const limit_val = Math.min(Number(new_val), maxMemberPrice).toString();
          this.$set(this.forms, "member_price", limit_val);
        },
        (new_val: string) => {
          this.$set(this.forms, "member_price", new_val);
        }
    );
  }

  private handleLimitSingleMaxCount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      }
    );
  }

  private sumbitSummerActRewardPoolLoading: boolean = false;

  private defaultRewardLoading: boolean = false;

  private handleSubmitDefaultReward():void{
    this.summerActRewardPoolApi.setDefaultReward(this.defaultRewardForms,
        () => {
          this.defaultRewardVisible = false;
          this.defaultRewardLoading = false;
        },
        () => {
          this.defaultRewardLoading = false;
        }
    )
  }

  // 提交 奖品
  private handleSumbitSummerActRewardPool() {
    const summerActRewardPoolDialogMode = this.summerActRewardPoolDialogMode;

    const params = this.getSummerActRewardPoolFormsParams(summerActRewardPoolDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitSummerActRewardPoolLoading = true;
    switch (summerActRewardPoolDialogMode) {
      case "new-summer-act-reward-pool":
        this.summerActRewardPoolApi.addSummerActRewardPools(
          params,
          () => {
            this.getSummerActRewardPools();
            this.dialogFormVisible = false;
            this.sumbitSummerActRewardPoolLoading = false;
          },
          () => {
            this.sumbitSummerActRewardPoolLoading = false;
          }
        );
        break;
      case "edit-summer-act-reward-pool":
        this.summerActRewardPoolApi.updateSummerActRewardPools(
          params,
          () => {
            this.getSummerActRewardPools();
            this.dialogFormVisible = false;
            this.sumbitSummerActRewardPoolLoading = false;
          },
          () => {
            this.sumbitSummerActRewardPoolLoading = false;
          }
        );
        break;
    }
  }

  private getSummerActRewardPoolFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      num,
      reward_rate,
      // avatar,
      avatar_id,
      type_icon,
      coupons_platform_id,
      member_price,
      sort,
    } = forms || {};

    const keys = ["reward_name", "type"];

    const key2EndKey: any = {
      reward_name: "reward_name",
      type: "type",
      
    };

    const key2ErrMsg: any = {
      reward_name: "奖品名称不能为空",
      type: "类型不能为空",
  
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (
        forms[key] !== "" &&
        forms[key] !== undefined &&
        forms[key] !== null
      ) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    if (num) {
      params.num = num
    }

    if (reward_rate) {
      params.reward_rate = reward_rate
    }

    if (typeof avatar_id == 'number') {
      params.image_id = avatar_id
    }



    if (params.type == 2) {
      if (type_icon) {
        params.type_icon = type_icon
      } else {
        showErrorMessage('虚拟物品的数量不可为空')
        return false
      }
    }

    if(params.type == 3){
      if(coupons_platform_id){
        params.coupons_platform_id = coupons_platform_id
      }
    }

    if(params.type == 4){
      if(member_price){
        params.member_price = member_price
      }
    }
    

    if (typeof sort == "string") {
      params.sort = Number(sort);
    }

    console.log(id, "id");

    if (typeof id == "number") {
      params.id = id;
    }

    return params;
  }

  //获取优惠券列表
  private getSummerActRewardPools(): void {
    this.summerActRewardPoolApi.getSummerActRewardPools(
      { ...this.getSummerActRewardPoolParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.list;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private platformCouponsList:any[] = []
  private getPlatformCouponsList():void{
    this.summerActRewardPoolApi.couponsPlatformList(
        {page:1,limit: 99999 },
        (res: any) => {
          this.platformCouponsList = res.data.list;
        },
        () => {}
    );
  }


  private getDefaultRewardInfo():void{
    this.summerActRewardPoolApi.getDefaultRewardInfo({},
        (res: any) => {

          this.defaultRewardForms = res.data.reward_default_info;
        },
        () => {}
    );
  }




  private getSummerActRewardPoolParams(): any {
    const params: any = {};

    // const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    // const { shop_name } = form;

    // if (shop_name) {
    //   params.name = shop_name;
    // }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };
}
